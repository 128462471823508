<template>
    <div>
        <div class="home row" v-if="$route.path == '/teacherType'">
            <div class="home-body col-xs-12 col-sm-6 col-md-4" v-for="(item,index) in homeList" :key="index">
                <q-card class="home-option" flat v-if="$i18n.locale=='zh'">
                    <q-card-section>
                        <div class="card-title">{{ item.title }}</div>
                        <div class="card-des">
                            <p>{{ item.name_cn }}</p>
                            <img :src="item.icon_image" alt="">
                        </div>
                    </q-card-section>
                    <q-separator style="background: #d3d3d3"/>
                    <q-card-actions>
                        <div class="card-btn">
                            <q-btn class="card-btn-star" @click="clickStart(index)" flat>{{$t('home.start')}}</q-btn>
                        </div>
                    </q-card-actions>
                </q-card>
                <q-card class="home-option" flat v-if="$i18n.locale=='en'">
                    <q-card-section>
                        <div class="card-title">{{ item.title_en }}</div>
                        <div class="card-des">
                            <p>{{ item.name_en }}</p>
                            <img :src="item.icon_image" alt="">
                        </div>
                    </q-card-section>
                    <q-separator style="background: #d3d3d3"/>
                    <q-card-actions>
                        <div class="card-btn">
                            <q-btn class="card-btn-star" @click="clickStart(index)" flat>{{$t('home.start')}}</q-btn>
                        </div>
                    </q-card-actions>
                </q-card>
            </div>
            <!--购买组题权限 弹窗-->
            <Buy v-if="$store.state.buy"></Buy>
        </div>
        <router-view v-else></router-view>
    </div>
</template>

<script>
    export default {
        name: "teacherType",
        data(){
            return{
                homeList: []
            }
        },
        created() {
            this.getTeacherType()
        },
        methods:{
          // 获取老师组卷类型
            getTeacherType(){
                this.$http.post('exam/classify_knowledge').then(res =>{
                    this.homeList = res.data
                })
            },
            clickStart(id){
                console.log(id)
                this.$router.push({path:'/teacherManualModular',query:{id:id}})
            }
        }
    }
</script>

<style scoped lang="less">
    .home {
        padding-bottom: 20px;
        padding-left: 30px;
        padding-right: 15px;
        margin-top: 20px;
    }
    .home-body {
        margin-bottom: 20px;
    }
    .home-option {
        border: 1px solid #d3d3d3;
        border-radius: 5px;
        margin-right: 15px;
    }
    .card-title {
        font-size: 24px;
        color: #333333;
        margin-bottom: 14px;
    }
    .card-des {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 44px;
        p {
            height: 50px;
            max-width: 171px;
            font-size: 12px;
            color: #999999;
            margin-bottom: 5px;
            /*margin-top: 27px;*/
        }
    }
    .card-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .card-btn-star {
            width: 70px;
            height: 37px;
            border: 1px solid #ED711C;
            border-radius: 19px;
            font-size: 13px;
            color: #ED711C;
        }
    }
</style>